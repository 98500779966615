<template>
  <component :is="layout">

      <div v-if="table_time" class="card p-2 shadow m-2 text-center">
          <h3 class="text-dark">{{table_time.game_name}}</h3>
          <button @click="addTableGame()" class="btn btn-warning p-1 text-white btn-block w-100">Add Panel</button>
          <table v-if="table_games" class="table table-responsive">
              <thead>
                <th>Date</th>
                
                <th>Actions</th>
              </thead>
              <tbody>
                  <tr v-for="(panel,index) in table_games.data" :key="panel.id">
                    <td>{{ formatDate(panel.created_at) }}</td>
                    
                   <td>
                       <button @click="show_table_game(index)" class="btn bg-primary btn-primary m-1">Edit</button>
                        <button @click="delete_table_game(panel.id)" class="btn bg-danger btn-danger m-1">Delete</button>
                   </td>
                  </tr>
              </tbody>
          </table>
         <pagination class="text-dark" :per-page="10" v-model="page" :records="table_games.total" @paginate="fetchTableGames"/>
      </div>
      <!-- edit table game panel -->
    <Modal v-if="table_time" :showModal="show_edit_table">
         <template v-slot:header>
            <h3>{{ table_time.game_name }}</h3>
         </template>
         <template v-slot:body>
          <div class="row justify-content-around">
              <input v-if="table_time.one" placeholder="game 1" class="col-6 p-1" size="10" v-model="table_game.one" type="text">
              <input v-if="table_time.two"  placeholder="game 2" class="col-6 p-1" size="10" v-model="table_game.two" type="text">
              <input v-if="table_time.three"  placeholder="game 3" class="col-6 p-1" size="10" v-model="table_game.three" type="text">
              <input v-if="table_time.four"  placeholder="game 4" class="col-6 p-1" size="10" v-model="table_game.four" type="text">
              <input v-if="table_time.five"  placeholder="game 5" class="col-6 p-1" size="10" v-model="table_game.five" type="text">
              <input v-if="table_time.six"  placeholder="game 6" class="col-6 p-1" size="10" v-model="table_game.six" type="text">
              <input v-if="table_time.seven"  placeholder="game 7" class="col-6 p-1" size="10" v-model="table_game.seven" type="text">
              <input v-if="table_time.eight"  placeholder="game 8" class="col-6 p-1" size="10" v-model="table_game.eight" type="text">
              <input  v-if="table_time.nine" placeholder="game 9" class="col-6 p-1" size="10" v-model="table_game.nine" type="text">
              <input  v-if="table_time.ten" placeholder="game 10" class="col-6 p-1" size="10" v-model="table_game.ten" type="text">
              <input  v-if="table_time.eleven" placeholder="game 11" class="col-6 p-1" size="10" v-model="table_game.eleven" type="text">
              <input  v-if="table_time.twelve" placeholder="game 12" class="col-6 p-1" size="10" v-model="table_game.twelve" type="text">
              <input  v-if="table_time.thirteen" placeholder="game 13" class="col-6 p-1" size="10" v-model="table_game.thirteen" type="text">
              <input  v-if="table_time.fourteen" placeholder="game 14" class="col-6 p-1" size="10" v-model="table_game.fourteen" type="text">
          </div>
         </template>
         <template v-slot:footer>
             <button @click="update_table_game()" class="btn bg-success btn-success modal-default-button">Update</button>
        <button
          class="btn bg-danger btn-danger modal-default-button"
          @click="show_edit_table = false"
        >
          close
        </button>
         </template>
    </Modal>
  </component>
</template>

<script>

import Modal from "../Modal.vue";
import Pagination from 'vue-pagination-2';
import axiosConfig from '../../axiosConfig';
import axiosObject from '../../axiosHandler';
export default {
    components:{
        Modal,
        Pagination
    },
  computed:{
      layout()
      {
          return (this.$route.meta.layout)
      }
  },
  data(){
      return{
          table_time:null,
          table_games:null,
          table_game:null,
          show_edit_table:false,
          page:1
      }
  },
  created()
  {
    this.fetchTableTime()
  },
  methods:{
      fetchTableTime()
      {
         axiosObject.get("/api/client/table_time",axiosConfig)
        .then(
          function (response) {
            this.table_time = response.data.users.table_time;
            this.fetchTableGames()
          }.bind(this)
        )
        .catch(function (error) {
         alert(error.response.data.message);
          if(error.response.status == 401){
            localStorage.clear();
            window.location.reload()
          };
        });
      },
      fetchTableGames(page =1){
          this.page = page
          axiosObject.get('/api/client/table_game?page='+page,axiosConfig)
          .then(function(response){
           this.table_games = response.data.table_games
          }.bind(this)).catch(function(error){
             alert(error.response.data.message);
          if(error.response.status == 401){
            localStorage.clear();
            window.location.reload()
          }
          })
      },
      addTableGame(){
     const id = 0
     axiosObject.post("/api/admin/table_game",{'id':id},axiosConfig)
     .then(function(response){
         this.fetchTableGames()
     }.bind(this)).catch(function(error){
        alert(error.response.data.message);
          if(error.response.status == 401){
            localStorage.clear();
            window.location.reload()
          }
     })
    },
    show_table_game(index)
    {
       this.table_game = this.table_games.data[index]
       this.show_edit_table = true
    },
    update_table_game()
    {
       axiosObject.patch('/api/client/table_game/'+this.table_game.id,this.table_game,axiosConfig)
       .then(function(response){
           this.show_edit_table =false
       }.bind(this)).catch(function(error){
          alert(error.response.data.message);
          if(error.response.status == 401){
            localStorage.clear();
            window.location.reload()
          }
       })
    },
    delete_table_game(id)
    {
      axiosObject.delete('/api/client/table_game/'+id,axiosConfig).then(function(response){
       this.fetchTableGames(this.page)
      }.bind(this)).catch(function(error){
         alert(error.response.data.message);
          if(error.response.status == 401){
            localStorage.clear();
            window.location.reload()
          }
      })
    },
    formatDate(date)
    {
        return new Date(date).toLocaleDateString("hi-IN")
    },
  }
}
</script>

<style>

</style>
